import { useContextSelector } from 'use-context-selector'

import { City } from '@Schemas/domain/state'

import { CityContext } from '@Context/city-context'

type cityHook = () => {
  city: City
  cityHistory: City[]
  setCity: (city: City, redirect?: boolean) => void
  isReady: boolean
}

const useCity: cityHook = () => {
  const city = useContextSelector(CityContext, (context) => context?.city)

  const setCity = useContextSelector(
    CityContext,
    (context) => context?.onSetCity
  )

  const cityHistory = useContextSelector(
    CityContext,
    (context) => context?.cityHistory
  )

  const isReady = useContextSelector(CityContext, (context) => context?.isReady)

  return { city, setCity, cityHistory, isReady }
}

export default useCity
