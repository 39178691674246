import { z } from 'zod'

export const CitySchema = z.object({
  id: z.string(),
  name: z.string(),
  uf: z.string().min(2).max(2),
  state: z.string(),
  urlKey: z.string(),
  timeZone: z.string().optional(),
  distance: z.number().optional()
})

export const StateSchema = z.object({
  name: z.string().optional(),
  uf: z.string().min(2).max(2),
  cities: z.array(CitySchema)
})

export const StatesSchema = z.array(StateSchema)

export type State = z.infer<typeof StateSchema>
export type States = z.infer<typeof StatesSchema>
export type City = z.infer<typeof CitySchema>
