import { deleteCookie, getCookie, setCookie } from 'cookies-next'

const cookies = {
  get: (name: string, options?: any) => {
    return getCookie(name, options) as string
  },
  set: (name: string, value: string, options?: any) => {
    setCookie(name, value, options)
  },
  destroy: (name: string, options?: any) => {
    deleteCookie(name, options)
  }
}

export default cookies
