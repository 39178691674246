export const clearName = (name: string) =>
  name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLocaleLowerCase()

export const toPascalCase = (s) =>
  s.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase()
  })

export const splitCountry = (countryName: string) =>
  clearName(countryName)
    .trim()
    .replace(/( \/ )|( , )|(\/)|(, )/g, '*')
    .split('*')

export const isExternalUrl = (url: string) => {
  const regexUrl =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi
  return regexUrl.test(url)
}

export const formatPrice = (price: number | string) => {
  const value = typeof price === 'string' ? parseFloat(price) : price

  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const formatPhoneNumber = (phoneNumber: string) => {
  const phone = phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')
    .split('_')
    .join('')

  return phone
}

export const phoneMask = (phoneNumber: string) => {
  const mobilePhone = phoneNumber[2] === '9'
  return mobilePhone ? '(99) 99999-9999' : '(99) 9999-9999'
}

export const toSentenceCase = (url: string) => {
  if (!url) return url

  const words = url.replace(/[-_]/g, ' ')

  const sentence = words.charAt(0).toUpperCase() + words.slice(1).toLowerCase()

  return sentence
}

export const getVideoId = (url: string) => url?.split('=')[1]

export const getThumbnailUrl = (videoId: string) =>
  `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
