import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { avatars } from '../../constants/avatars'
import { corporations } from '../../constants/corporations'

export const CalculateDistance = (
  from: { latitude?: number; longitude?: number },
  to: {
    latitude?: number
    longitude?: number
  },
  stringReturn?: boolean
) => {
  if (from.latitude && from.longitude && to.latitude && to.longitude) {
    const theta = from.longitude - to.longitude
    const pi = Math.PI / 180
    const p = 180 / Math.PI
    const x = 60
    const y = 1.1515
    const distance =
      x *
      y *
      p *
      Math.acos(
        Math.sin(from.latitude * pi) * Math.sin(to.latitude * pi) +
          Math.cos(from.latitude * pi) *
            Math.cos(to.latitude * pi) *
            Math.cos(theta * pi)
      )

    const result = distance * 1.609344

    return stringReturn ? `${result.toFixed(1)} Km` : result
  }
}

export const getAvatar = (corporationId) => {
  const avatar =
    avatars.find((item) => item.corporationId === corporationId) || avatars[0]
  return avatar?.imageUrl
}

export const getCorporationColor = (corporationName: string) => {
  const corporationColor = corporations.find(
    (corporation) => corporation.caption === corporationName
  )
  return corporationColor ? corporationColor.style : 'bg-black'
}

export const formatedDuration = (duration = 0) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration - 60 * hours

  return duration !== 0
    ? `${hours}h${minutes > 0 ? String(minutes).padStart(2, '0') : ''}`
    : ``
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
