'use client'

import { useCallback, useState } from 'react'
import { createContext } from 'use-context-selector'

type AdvertiseContext = {
  uolAds: boolean
  onSetAds: (hasLoaded?: boolean) => void
}

export const AdvertiseContext = createContext({} as AdvertiseContext)

export const AdvertiseProvider = ({ children }) => {
  const [uolAds, setUolAds] = useState(false)

  const onSetAds = useCallback((hasLoaded) => {
    setUolAds(hasLoaded)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdvertiseContext.Provider
      value={{
        uolAds,
        onSetAds
      }}
    >
      {children}
    </AdvertiseContext.Provider>
  )
}
