'use client'

import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useCallback } from 'react'

import { isExternalUrl } from '@Shared/helpers/string-operator'

type LinkProps = NextLinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    openExternalInSameTab?: boolean
  }

const Link = ({
  href = '',
  as,
  children,
  onClick,
  openExternalInSameTab,
  ...rest
}: LinkProps) => {
  const queryParams = useSearchParams()

  const urlBuilder = useCallback(() => {
    if (typeof window === 'undefined') {
      return href
    }

    const excludeQueryParams = ['q', 'filter_by']
    const urlWithoutSlash = href.startsWith('/') ? href.replace('/', '') : href

    let url = new URL(
      isExternalUrl(href)
        ? href
        : `${window.location.origin}/${urlWithoutSlash}`
    )

    queryParams.forEach((value, name) => {
      !excludeQueryParams.includes(name) && url.searchParams.set(name, value)
    })

    return url.href
  }, [href, queryParams])

  return children && isExternalUrl(href) ? (
    <a
      href={href}
      onClick={onClick}
      {...rest}
      target={openExternalInSameTab ? '_self' : '_blank'}
      rel="noreferrer noopener"
    >
      {children}
    </a>
  ) : href.startsWith('#') ? (
    <a href={urlBuilder()} onClick={onClick} {...rest}>
      {children}
    </a>
  ) : (
    <NextLink
      href={urlBuilder()}
      as={as}
      passHref
      prefetch={Boolean(rest.prefetch)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NextLink>
  )
}

export default Link
