import { usePathname } from 'next/navigation'

import * as pages from '@Shared/constants/page-name'

export const usePageName = () => {
  const path = usePathname()
  const splitedPath = path?.split('/')
  const institutionalPageNames = {
    'assessoria-imprensa': pages.PRESS_OFFICE,
    'ingresso-atende': pages.CUSTOMER_SERVICE
  }

  const eventsPageNames = () => {
    if (splitedPath.length > 2) {
      return splitedPath[2] === 'local'
        ? pages.EVENTS_PLACE
        : pages.EVENTS_EVENT_TYPE
    }

    return pages.EVENTS
  }

  const newsPageNames = splitedPath.length > 2 ? pages.ARTICLE : pages.NEWS

  const moviesPageNames =
    splitedPath.length > 2 ? pages.FESTIVAL_MOVIES : pages.MOVIES

  const pageNameList = {
    filmes: moviesPageNames,
    filme: pages.MOVIE,
    cinemas: pages.THEATERS,
    cinema: pages.THEATER,
    evento: pages.EVENT,
    teatros: pages.THEATRES,
    promocoes: pages.PROMOTIONS,
    'vip-zone': pages.VIP_ZONE,
    busca: pages.SEARCH_RESULT,
    noticias: newsPageNames,
    eventos: eventsPageNames(),
    politicas: pages.SCAM_WARNING,
    institucional: institutionalPageNames[splitedPath[2]],
    '404': pages.NOT_FOUND
  }

  const pageName = pageNameList[splitedPath[1]] || pages.HOME

  return pageName
}
