import { useContextSelector } from 'use-context-selector'

import { AdvertiseContext } from '@Context/advertise-context'

type advertiseStoreHook = () => [boolean, (hasLoaded: boolean) => void]

const useAdvertiseStore: advertiseStoreHook = () => {
  const advertiseLoaded = useContextSelector(
    AdvertiseContext,
    (context) => context?.uolAds
  )

  const setAdvertise = useContextSelector(
    AdvertiseContext,
    (context) => context?.onSetAds
  )

  return [advertiseLoaded, setAdvertise]
}

export default useAdvertiseStore
