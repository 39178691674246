export const corporations = [
  {
    caption: 'Cinemark',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_1.svg',
    style: 'bg-[#a53336]'
  },
  {
    caption: 'Kinoplex',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_2.svg',
    style: 'bg-[#f26822]'
  },
  {
    caption: 'Espaço Itaú',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_4.svg',
    style: 'bg-[#fff]'
  },
  {
    caption: 'Cinépolis',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_5.svg',
    style: 'bg-[#064a89]'
  },
  {
    caption: 'UCI',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_3.svg',
    style: 'bg-[#252764]'
  },
  {
    caption: 'Araújo',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_10.svg',
    style: 'bg-[#000]'
  },
  {
    caption: 'Estação',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_8.svg'
  },
  {
    caption: 'Cinesystem',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_9.svg',
    style: 'bg-[#d3312b]'
  },
  {
    caption: 'GNC',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_16.svg',
    style: 'bg-[#fff]'
  },
  {
    caption: 'Cineart',
    url: 'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_14.svg',
    style: 'bg-[#1d3d71]'
  }
]

export const theatersCorporationChanged = [
  {
    id: '316',
    name: 'Cinesystem Botafogo',
    path: '/cinema/cinesystem-botafogo'
  },
  {
    id: '958',
    name: 'Cinesystem Brasília',
    path: '/cinema/cinesystem-brasilia'
  },
  {
    id: '675',
    name: 'Cinesystem Pompéia',
    path: '/cinema/cinesystem-pompeia'
  },
  {
    id: '130',
    name: 'Cinesystem Frei Caneca',
    path: '/cinema/cinesystem-frei-caneca'
  }
]
