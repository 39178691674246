'use client'

import { useRouter, useSearchParams } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'
import { createContext } from 'use-context-selector'

import {
  DEFAULT_CITY,
  DEFAULT_COOKIE_DOMAIN,
  DEFAULT_COOKIE_EXPIRATION_TIME,
  MAX_CITY_HISTORY_SIZE,
  SITE_CITY_COOKIE
} from '@Shared/constants/application'
import { THEATER } from '@Shared/constants/page-name'
import { useCityQuery } from '@Shared/hooks/use-city-query'
import useLocalStorage from '@Shared/hooks/use-local-storage'
import { usePageName } from '@Shared/hooks/use-page-name'

import { City } from '@Schemas/domain/state'

import { cookies } from '@Services/cookies'

type CityContext = {
  city: City
  cityHistory: City[]
  onSetCity: (city: City) => void
  isReady: boolean
}

type CityContextProps = {
  children: ReactNode
}

const setCityCookie = (city: City) => {
  cookies.set(
    SITE_CITY_COOKIE,
    JSON.stringify({
      Id: city.id,
      Name: city.name,
      UrlKey: city.urlKey,
      UF: city.uf,
      State: city.state
    }),
    {
      domain: DEFAULT_COOKIE_DOMAIN(),
      path: '/',
      expires: DEFAULT_COOKIE_EXPIRATION_TIME()
    }
  )
}

export const CityContext = createContext({} as CityContext)

export const CityProvider = ({ children }: CityContextProps) => {
  const router = useRouter()
  const pageName = usePageName()
  const hasCityCookie = Boolean(cookies.get(SITE_CITY_COOKIE))
  const cityParam = useSearchParams().get('city')

  const [isReady, setIsReady] = useState(false)

  const [city, setCity] = useState<City>(() => {
    if (hasCityCookie) {
      const cityCookie = JSON.parse(cookies.get(SITE_CITY_COOKIE))

      return {
        id: cityCookie?.Id,
        name: cityCookie?.Name,
        uf: cityCookie?.UF,
        urlKey: cityCookie?.UrlKey,
        state: cityCookie?.State
      }
    }

    setCityCookie(DEFAULT_CITY)
    return DEFAULT_CITY
  })

  const { data: cityQuery } = useCityQuery(
    { urlKey: cityParam as string },
    { enabled: Boolean(cityParam) }
  )

  const [cityHistory, setCityHistory] = useLocalStorage<City[]>('cityHistory', [
    city
  ])

  const onSetCity = (city: City, redirect?: boolean) => {
    setCity(city)
    setCityCookie(city)
    setIsReady(true)

    setCityHistory((history) => {
      const newHistory = history.filter(
        (previousCity) => previousCity.id !== city.id
      )
      newHistory.push(city)

      return newHistory.length > MAX_CITY_HISTORY_SIZE
        ? newHistory?.slice(newHistory.length - MAX_CITY_HISTORY_SIZE)
        : newHistory
    })

    if (redirect) {
      let url = new URL(window.location.href)

      url.searchParams.set('city', city.urlKey)

      if (pageName.toLowerCase() === THEATER) {
        url.pathname = '/'
      }

      router.push(url.href)
    }
  }

  useEffect(() => {
    onSetCity(cityQuery || city)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityQuery])

  return (
    <CityContext.Provider value={{ city, onSetCity, cityHistory, isReady }}>
      {children}
    </CityContext.Provider>
  )
}
