export const SITE_CITY_COOKIE = 'SiteCity'
export const ACCESS_TOKEN = 'token'
export const REFRESH_TOKEN = 'cmVmcmVzaHRva2Vu'
export const KEEP_CONNECTED = 'keepMeConnected'
export const FINGERPRINT = 'zYpQrSfGhTjKlNmO'

export const CLIENT_ORIGIN = 'site'

export const DEFAULT_CITY = {
  id: '1',
  name: 'São Paulo',
  uf: 'SP',
  urlKey: 'sao-paulo',
  state: 'São Paulo'
}

export const DEFAULT_COOKIE_DOMAIN = () => {
  return globalThis?.document?.location?.hostname === 'localhost'
    ? 'localhost'
    : process.env.COOKIE_DOMAIN
}

/**
 * @default one year duration cookie
 * @returns
 */
export const DEFAULT_COOKIE_EXPIRATION_TIME = () => {
  const expiration = new Date()
  expiration.setFullYear(expiration.getFullYear() + 1)
  return expiration
}

export const DEFAULT_USER_DATA = {
  id: '',
  token: '',
  name: '',
  email: '',
  cpf: ''
}

export const DEFAULT_PARTNERSHIP = { id: 'home', images: [] }
export const MAX_CITY_HISTORY_SIZE = 5

export const MOVIE_PAGE_SESSIONS_FILTER_ID = 'movie-page-sessions-filter'
