export const avatars = [
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_default.svg',
    corporationId: '0'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_1.svg',
    corporationId: '1'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_2.svg',
    corporationId: '2'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_3.svg',
    corporationId: '3'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_4.svg',
    corporationId: '4'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_5.svg',
    corporationId: '5'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_6.svg',
    corporationId: '6'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_8.svg',
    corporationId: '8'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_9.svg',
    corporationId: '9'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_10.svg',
    corporationId: '10'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_11.svg',
    corporationId: '11'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_14.svg',
    corporationId: '14'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_16.svg',
    corporationId: '16'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_19.svg',
    corporationId: '19'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_165.svg',
    corporationId: '165'
  },
  {
    imageUrl:
      'https://ingresso-a.akamaihd.net/img/cinema/avatar/avatarcorporation_12.svg',
    corporationId: '12'
  }
]
